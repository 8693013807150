*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
[draggable="true"] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.drop-area {
  position: relative;
  /* padding: 0px 21px 28px 21px; */
  /* border: 1px solid #999; */
  left: 8%;
}
.drop-area .item {
  position: absolute;
  cursor: pointer;
  width: 100px;
  font-size: 105%;
  /* border: solid 1px black; */
  border-radius: 5px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.chakra-portal>.css-3l0anw{
  z-index: 100;
}
.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.drop-area .item .resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
