@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
.wdth-sdk {
  margin: 3% 0% 0% 0%;
  height: 30px;
}
html {
  overflow-x: hidden;
}
.jsx-4179805763 {
  width: 100% !important ;
}
ul.open.jsx-4179805763
{
  background: white !important;
  z-index: 9;
}
.img-cen {
  justify-content: center;
}
.tag-div {
  .react-tagsinput-tag {
    background-color: #b6b6b6;
    border-radius: 2px;
    border: 1px solid #060703;
    color: #000000;
  }
  .react-tagsinput--focused {
    border-color: #060703;
  }
}
.d-grid {
  display: grid !important;
}
.pd-radio {
  padding: 0px 0px 8px 8px;
}
.cstmcheckbx .chakra-checkbox__control {
  border: 1px solid #94a3a8;
  border-radius: 4px;
  width: 21px;
  height: 21px;
}
.l-pad {
  padding: 40px 0px;
  height: 100%;
  position: absolute;
}
.pro-sidebar-inner {
  background: #ffffff !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 0px 8px 8px 0px;
}
.body-bg {
  background: #eef4f6;
}
.sidebarButton {
  position: absolute;
  width: 54px;
  height: 54px;
  display: flex;
  top: 0;
}
.pro-item-content {
  /* position: absolute; */
  width: 191px;
  height: 40px;
  /* left: 40px; */
  padding: inherit;
  /* top: 236px; */
  background: rgba(1, 173, 155, 0.16);
  border: 2px solid #01ad9b;
  box-sizing: border-box;
  border-radius: 16px;
  justify-content: center;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #01ad9b;
}
.v-stack {
  padding: 40px 64px 40px 116px;
  height: 100%;
}
.first-stack {
  height: 104px;

  background: #ffffff;
  /* Shadow - Natural */

  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  border-radius: 8px;
}
.firstStack-mg {
  margin: 22px 10px;
  width: 100%;
}
.add-service-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;

  flex-grow: 0;
  margin: 0px 10px;
  width: 150px !important;
  height: 40px;

  background: #0e5e81 !important;
  box-shadow: 0px 4px 8px rgb(14 94 129 / 20%);
  border-radius: 8px !important;
}
.btn-2 {
  padding: 12px 24px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important ;
  font-size: 14px !important ;
  line-height: 16px !important ;

  text-align: center;
  letter-spacing: 0.02em !important ;
  color: #0e5e81;
  text-shadow: 0px 15px 30px rgb(73 50 87 / 25%);

  width: 127px !important ;

  background: #ffffff !important ;
  border: 2px solid #0e5e81;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgb(14 94 129 / 20%);
  border-radius: 8px !important ;
}
.divider {
  position: absolute;
  width: 46px;
  height: 0px;
  /* left: 1185px; */
  margin-left: 140px;
  top: 82px;
  border: 1px solid #94a3a8;
  transform: rotate(90deg);
}
.fl-right {
  float: right !important;
}
.mg-left {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;
}
.heading-sidebar {
  top: 8.89%;
  bottom: 88.89%;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #222c2f;
}
.sidebar-desc {
  margin-top: 18px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(34, 44, 47, 0.7);
}
.check-text {
  width: max-content;
}
.topnav {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 5%;
  -webkit-filter: drop-shadow(0px 2px 12px rgba(61, 77, 81, 0.15));
  filter: drop-shadow(0px 2px 12px rgba(61, 77, 81, 0.15));
}
.topnav .chakra-breadcrumb__list {
  display: flex;
  align-items: center;
}

.topnav .chakra-breadcrumb__list-item {
  padding: 0 20px;
}
.topnav .chakra-breadcrumb__list-item {
  font-size: 16px;
}
.wrapper {
  padding: 0 5%;
}
.wrapper .css-13k6qti {
  margin: 0;
}
.tooglebtn {
  display: inline-block;
  width: 54px;
  position: absolute;
  right: -20px;
  z-index: 1400;
  height: 49px;
  top: 40px;
}
.tooglebtn svg {
  position: absolute;
  z-index: 99;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 1.5em;
  height: 1.5em;
}

.mod-subheading {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  color: rgba(34, 44, 47, 0.7);
  margin: 24px 0;
}

.mod-header {
  font-family: Ubuntu;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700 !important;
  text-align: left;
  padding: 0 !important;
  line-height: normal;
}

.mod-checklbl span {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #3d4d51;
}

.tag-lbl {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0e5e81;
  line-height: 16px;
  margin-right: 5px;
}

.edit-txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  color: #153e75;
  position: relative;
  top: 4px;
}

.tag-labels > span {
  margin: 0 5px 15px 0 !important;
}

.progress-div {
  position: absolute;
  top: auto;
  bottom: 10rem;
  left: 50%;
  transform: translate(-50%, -0%);
}
.txt-cn {
  text-align: left !important;
}
.chk-box {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
}
.txt-over {
  white-space: nowrap;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  overflow: visible;
}

.chk-bx-gap {
  margin: 10px 0px !important;
}

.lbl-progress-txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: #153e75;
  transform: rotate(0.57deg);
}

.cmn-tbl {
  border-collapse: separate !important;
  border-spacing: 0 8px;
}
.cmn-tbl tbody:before {
  line-height: 0;
  content: ".";
  color: white;
  display: block;
}
.cmn-tbl tr th {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-transform: capitalize;
}

.mod-service-add-edit {
  position: absolute;
  background: #ffffff;
  border-radius: 5px;
  padding: 56px 72px;
}

.acc-panel {
  background: #f7fbfd;
  box-shadow: "0px 4px 8px rgba(32 35 50 0.2)";
  border-top: 1px solid #c4c4c4;
  border-radius: 0px 0px 4px 4px;
}

.acc-header {
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #222c2f;
}

.frm-lbl {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #3d4d51;
}

.tg-cmmt {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 28px;
  color: #222c2f;
}

.sug-tag {
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #222c2fb2;
}

.chk-sub-lbl {
  font-family: Ubuntu;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #3d4d51;
}

.fm-ip-flds {
  background: #f7f9fc !important;
  border: 2px solid #94a3a8 !important;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(124, 124, 236, 0.1);
  border-radius: 4px !important;
}
 .optionContainer li:hover,
 .optionContainer .highlight {
   background:coral ;
 }
.un-lbl {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #222c2f;
  margin-top: 18px;
  margin-left: 15px;
}

.nb-hdr {
  font-family: Ubuntu;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d4d51;
}

/* Notification Modal */

.md-msg {
  font-family: Ubuntu;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #222c2f;
}

.md-txt {
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #222c2f;
}

.btn-save {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-align: center;
  width: 147px !important;
  height: 40px !important;
  color: rgb(14, 94, 129);
  background: rgb(255, 255, 255) !important;
  border: 2px solid rgb(14, 94, 129);
  box-shadow: rgba(14, 94, 129, 0.2) 0px 4px 8px;
  border-radius: 8px !important;
}

.btn-pbh {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500 !important;
  width: 98px !important;
  height: 40px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  background: rgb(14, 94, 129) !important;
  border-radius: 8px !important;
  color: rgb(255, 255, 255);
}

.md-ntf {
  height: 640px;
  max-width: 720px !important;
  border-radius: 4px;
}

/* Add Map Modal */

.btn-ad-mp {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500 !important;
  width: 245px !important;
  height: 64px !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 24px;
  background: rgb(14, 94, 129) !important;
  box-shadow: rgba(14, 94, 129, 0.2) 0px 4px 8px;
  border-radius: 32px !important;
  color: rgb(255, 255, 255);
}

.md-hdr-fnt {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #3d4d51;
}

.md-ip-lbl {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #3d4d51;
}

.btn-cnl {
  background-color: transparent !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-right: 15px;
  border-radius: 8px;
}

.btn-fnt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0e5e81;
}

.btn-crt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff !important;
}

.btn-crt-sty {
  background: #0e5e81 !important;
  box-shadow: 0px 4px 8px rgba(14, 94, 129, 0.2);
  border-radius: 32px !important;
  padding: 12px 24px 12px 24px;
}

/* Published Form */

.pf-gry-crd {
  background: #f0f6fa;
  border-radius: 10px;
  padding: 15px;
}

.pf-hdr {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #153e75;
  padding: 0px !important;
}

.pf-pt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.pf-sb-pt {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

/* Service changes log */

.md-lg {
  max-width: 1099px !important;
  position: absolute !important;
  margin-left: 224px;
  margin-top: 80px !important;
  background: #dbe2ec !important;
  border-radius: 10px !important;
  padding: 40px 40px 32px 40px !important;
}

/* common css */

.col-gray {
  color: #3d4d51;
}
.ff-ubuntu {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
}
.f-14 {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

/* .dt-fc {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 37.5%;
  bottom: 0%;
  background: #F7F9FC;
  border: 2px solid #94A3A8;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(124, 124, 236, 0.1);
  border-radius: 4px;
} */

/* add service */
.service-circle {
  position: absolute;
  right: 0;
  left: auto;
  top: 2px;
  bottom: auto;
}
.service-type label {
  padding-right: 25px;
}
.map-grid
{
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }
}
.tooltipsdk {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  .number {
    color: #0e5e81;
  }
}
.service-name
{
  background: #f7f9fc !important;
  border: 2px solid #94a3a8 !important;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgb(124 124 236 / 10%);
  border-radius: 4px !important;
  width: 100%;
  padding: 7px;
}
.btnHover:hover
{
  background: #747474 !important
}
.timezone{
    width: 100%;
    font: inherit;
    border: 1px solid #e6ebec;
    border-radius: 2px;
    color: #474747;
    display: inline-block;
    font: 13px sans-serif;
    position: relative;
}
