.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.trs{
  border-right: 8px solid #15191d00 !important;
} */
.firstline {
  height: 100%;
  border-left: 4px solid #edf2f7 !important;
}
.midLine {
  /* transform: matrix(1, 0, 0, 1, 0, 0); */
  /* border: 3px solid #edf2f7; */
  /* width: 3px; */
  /* background: #edf2f7; */
  border-radius: 0;
  border-right: 4px solid #edf2f7;
  display: flex;           
  align-items: center;     
  justify-content: center; 
  min-height: 400px;
  border-left: 8px solid transparent;
  /* height: 100% */
}
.midLine2 {
  position: absolute;
  left: 30%;
  top: 0;
  min-height: 400px;
  bottom: auto;
  border-radius: 0;
  border-right: 3px dashed #edf2f7;
  /* height: 100% */
}
.midLine3 {
  position: absolute;
  left: 66%;
  top: 0;
  min-height: 400px;
  border-radius: 0;
  border-right: 3px dashed #edf2f7;
  /* height: 100% */
}
.ball {
  position: relative;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 70px;
  background: #edf2f7;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -55%;
}
.ball-text {
  font-family: Ubuntu;
  font-weight: 600;
  font-size: 40px;
  color: #a0aec0;
}
.market {
  text-align: center;
  font-family: Ubuntu;
  font-size: 20px;
  line-height: 140%;
  color: #3d4d51;
}
.organisation {
  width: 100%;
  text-align: center;
  font-family: Ubuntu;
  font-size: 20px;
  line-height: 140%;
  margin-right: 60px;
  color: #3d4d51;
}
.h-100 {
  height: 100%;
}
